export const ApiName = {
  generateGwtToken: "authenticateWeb",
  checkOperator: "checkOperator",
  signupweb: "signupWeb",
  verifyOtp: "verifyOtpWeb",
  subscribe: "subscribeWeb",
  ffSubscribe: "ffSubscribe",
  login: "loginWeb",
  utm_list: "utmList",
  unSubscribe: "unsubscribeWeb",
  checkUser: "checkUser",
  getFLow: "getFlow",
  awarenessFLow: "awarenessFlow",
  changePackageWeb:"change/package",
  package_list:"packages"
};
